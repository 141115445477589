var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "transition",
        { attrs: { name: _vm.transitionName } },
        [
          _vm.$route.meta.keepAlive
            ? _c(
                "keep-alive",
                [_c("router-view", { staticClass: "router" })],
                1
              )
            : _c("router-view", { staticClass: "router" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }